import React, { useEffect, useRef, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { ApiService } from '../Services/apiservices';

let apiServices = new ApiService();

function Offers() {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [sliderData, setSliderData] = useState('')
    const [sliderImg, setSliderImg] = useState('')
    const didMountRef = useRef(true);


    useEffect(() => {
        if (didMountRef.current) {
            ApiService.fetchData("popup-banner").then((res) => {
                if (res.status === "success") {
                    setSliderData(res.popupSlider)
                    setSliderImg(res.slider_image_path)
                }
            });
        }
        didMountRef.current = false;
    })
    useEffect(() => {
        const visitedBefore = localStorage.getItem('visitedNewsletter');
        if (!visitedBefore) {
            handleShow();
            localStorage.setItem('visitedNewsletter', 'true');
        }
    }, []);

    return (
        <>
            <Modal show={show} onHide={handleClose} centered>
                <Modal.Body className='modal-body-with-background'
                    style={{
                        backgroundImage: `url(${sliderImg + sliderData.slider_image})`,
                        height: '496px'
                    }}>
                    <span><button type="button" className="btn-close" onClick={handleClose}></button></span>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default Offers;
